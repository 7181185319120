html {
	/* overflow: hidden;
	height: 100%; */
	overscroll-behavior-y: none;
  overscroll-behavior-x: none;

}

body {
	/* height: 100%;
	overflow: auto; */
	overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

@media (prefers-reduced-motion: no-preference) {
}
